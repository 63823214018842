import Div100vh from 'react-div-100vh';
import styles from './index.module.css';

export const Home = () => {
  return (
    <Div100vh className={styles.page}>
      <header></header>
      <div style={{ textAlign: 'center' }}>
        <h1>Gecko River LLC</h1>
        <p>We make apps</p>
      </div>
      <p>geckoriverdev@gmail.com</p>
    </Div100vh>
  );
};
