import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { Home } from './pages/Home';
// import { PrivacyPolicy } from './pages/PrivacyPolicy';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/privacy" element={<PrivacyPolicy />} /> */}
      </Routes>
    </div>
  );
}

export default App;
